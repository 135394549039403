<template>
  <div>
    <v-snackbar
      v-for="alert in alerts.slice(0, 1)"
      :key="alert.id"
      :timeout="alert.timeout - 100"
      :color="alert.type"
      :value="true"
      absolute
      center
      bottom
    >
      {{ alert.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="$store.dispatch('alerts/remove_alert', alert)"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  computed: {
    alerts() {
      return this.$store.getters["alerts/list"];
    }
  }
};
</script>
